<template>
  <div class="hero-block">
    <div
      class="event-background"
      :style="{ 'background-image': `url(${eventBackgrount})` }"
      :class="[showStickyHeader ? 'squeeze-background' : '']"
    >
      <div class="event-hero">
        <div class="event-header" id="hero-header">
          <div
            class="event-back-btn cursor-pointer"
            @click="$router.push({ name: 'events' })"
          >
            <i class="material-icons">keyboard_arrow_left</i>
            <span>{{ $t("back") }}</span>
          </div>
          <div class="event-buttons">
            <div class="btn-share" v-if="canShare" @click="$emit('share')">
              <!-- <i class="material-icons">reply</i> -->
              <img src="@/assets/share_icon.svg" alt="share" />
            </div>

            <div class="buttons-section">
              <mem-button
                :btn-type="'secondary-dark'"
                class="btn-spase"
                @click="openMap"
                v-if="
                  event &&
                  (event.address || (event.latitude && event.longitude))
                "
              >
                {{ $t("directions") }}
              </mem-button>
              <mem-button :btn-type="'secondary-dark'" @click="viewTickets">
                {{ $t("view_tickets") }}
              </mem-button>
            </div>
          </div>
        </div>
        <div class="event-text">
          <div class="event-date">
            <span class="passed-event" v-if="!!event.is_passed">
              {{ $t("passed_event") + " • " }}
            </span>
            <span>{{ eventDate }}</span>
          </div>
          <div class="mem-font--hero-block__title event-name">
            {{ event.name }}
          </div>
          <div class="event-distance">
            <div v-if="event.distance">{{ event.distance + "K" }}</div>
            <div
              v-if="event.distance && event.obstacles_count"
              class="distance-divider"
            >
              •
            </div>
            <div v-if="event.obstacles_count">
              <span> {{ event.obstacles_count + " " + $t("obstacles") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rescheduled-block" v-if="showRescheduledBlock">
      <div class="status-bar">Spartan EDGE Ticket Protection</div>
      <div class="rescheduled-content">
        <div class="content-info">
          <h3 class="info-title">{{ $t("event_rescheduled_title") }}</h3>
          <span class="info-date">
            <img
              class="date-icon"
              :src="require('@/assets/calendar_icon.svg')"
            />
            <span class="date-text">{{ eventDate }}</span>
          </span>
          <div class="info-description">
            <p class="paragraph">{{ $t("event_rescheduled_desc_part_I") }}</p>
            <p
              class="paragraph"
              v-html="$t('event_rescheduled_desc_part_II', linkConfig)"
            ></p>
          </div>
        </div>
        <div class="content-cta">
          <button class="cta-button" @click="openRefundDialog">
            <img class="cta-icon" :src="require('@/assets/refund_icon.svg')" />
            <span class="cta-title">{{ $t("ticket_refund") }}</span>
          </button>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showDialog"
      class="mem-dialog"
      overlay-color="#000000"
      overlay-opacity="0.54"
      :max-width="480"
      light
      persistent
    >
      <refund-tickets-modal
        v-if="showRefundDialog"
        :event="event"
        :tickets="ticketsToRefund"
        @close="onCloseRefundDialog"
        @submit="submitRefundDialog"
      />
      <refund-confirm-modal
        v-if="showRefundConfirmDialog"
        :items="ticketsToRefund"
        @back="backRefundConfirmDialog"
        @submit="onCloseRefundDialog"
        @close="onCloseRefundDialog"
      />
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import RefundTicketsModal from "@/components/events/RefundTicketsModal";
import RefundConfirmModal from "@/components/events/RefundConfirmModal";
import localizeResolver from "@/common/mixins/localizeResolver";

export default {
  name: "HeroBlock",
  mixins: [localizeResolver],
  components: {
    RefundConfirmModal,
    RefundTicketsModal,
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data() {
    return {
      showDialog: false,
      showRefundDialog: false,
      showRefundConfirmDialog: false,
      ticketsToRefund: [],
      linkConfig: {
        linkStart:
          '<a style="color: #000000; text-decoration: none; font-weight: bold" href="//spartanrace.zendesk.com/hc/en-us/articles/4412819798167-Spartan-Ticket-Protection" target="_blank">',
        linkEnd: "</a>",
      },
    };
  },
  props: ["event", "showStickyHeader", "canShare"],
  computed: {
    eventDate() {
      let today = moment().format("YYYY-MM-DD"),
        tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
      //Today
      if (today === moment(this.event.start_date).format("YYYY-MM-DD"))
        return "Today, " + moment(this.event.start_date).format("MMM DD");
      // Tomorrow
      else if (tomorrow === moment(this.event.start_date).format("YYYY-MM-DD"))
        return "Tomorrow, " + moment(this.event.start_date).format("MMM DD");
      // Past event
      else if (this.event.is_passed)
        return moment(this.event.start_date).format("MMM DD");
      // Weekday
      else return moment(this.event.start_date).format("dddd, MMM DD");
    },
    eventDateInterval() {
      // let today = moment().format("YYYY-MM-DD");
      // let eventStartDate = moment(this.event.start_date).format("YYYY-MM-DD");
      // let eventEndDate = moment(this.event.end_date).format("YYYY-MM-DD");
      let startMonth = moment(this.event.start_date).format("M");
      let endMonth = moment(this.event.end_date).format("M");

      if (startMonth === endMonth) {
        return (
          moment(this.event.start_date).format("MMM DD") +
          " - " +
          moment(this.event.end_date).format("DD")
        );
      } else
        return (
          moment(this.event.start_date).format("MMM DD") +
          " - " +
          moment(this.event.end_date).format("MMM DD")
        );
    },
    eventBackgrount() {
      if (this.event.image_big) return this.event.image_big;
      else if (this.event.full_image_url) return this.event.full_image_url;
      else return "";
    },
    showRescheduledBlock() {
      return (
        this.event?.rescheduled &&
        this.event?.tickets?.some(
          (item) =>
            item?.is_purchased_by_me &&
            item?.can_rescheduled_refund &&
            item?.country === "US"
        )
      );
    },
  },
  methods: {
    openMap() {
      this.$emit("open-map");
    },
    viewTickets() {
      this.$emit("view-tickets");
    },
    onCloseRefundDialog() {
      this.showDialog = false;
      this.ticketsToRefund = [];
      this.showRefundDialog = false;
      this.showRefundConfirmDialog = false;
    },
    openRefundDialog() {
      this.showDialog = true;
      this.showRefundDialog = true;
      this.showRefundConfirmDialog = false;
    },
    submitRefundDialog(event) {
      this.showDialog = true;
      this.showRefundDialog = false;
      this.ticketsToRefund = [...event];
      this.showRefundConfirmDialog = true;
    },
    backRefundConfirmDialog() {
      this.showDialog = true;
      this.showRefundDialog = true;
      this.showRefundConfirmDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.squeeze-background {
  height: 295px !important;
}

// .shadow {
//   background-color: #000000;
//   position: sticky;
//   top: 65px;
// }

.hero-block {
  display: flex;
  flex-direction: column;

  .event-background {
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position-y: 50%;
    position: relative;

    height: 380px;

    .event-hero {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(000, 000, 000, 0.5);
      width: 100%;

      // padding: 40px;
      padding-top: 15px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;

      @media screen and (max-width: $mobile) {
        padding-bottom: 28px;
        padding-left: 16px;
        padding-right: 16px;
      }
      .event-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        padding: 25px 40px;
        min-height: 85px;

        transition: background-color 0.2s linear;

        .event-back-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          text-transform: uppercase;
          position: relative;

          i {
            position: absolute;
            font-size: 30px;
            left: -10px;
          }

          span {
            font-size: 14px;
            line-height: 14px;
            font-weight: bold;
            margin-left: 20px;
          }
        }

        .event-buttons {
          display: grid;
          flex-direction: row;
          grid-template-columns: 1fr max-content;
          // flex-wrap: wrap;

          @media screen and (max-width: $mobile) {
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;
            margin-top: 35px;
          }

          .buttons-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @media screen and (max-width: $mobile) {
              order: 1;
            }
            button {
              padding: 0 20px;
              height: 35px;
              font-size: 12px;
            }
          }

          .btn-spase {
            margin-right: 12px;
          }

          .btn-share {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            height: 35px;
            width: 35px;
            background-color: #ffffff;
            color: #000000;
            border-radius: 50%;
            margin-right: 12px;
            @include cursorPointer;
            @media screen and (max-width: $mobile) {
              order: 2;
              margin-right: 0;
            }
            // i {
            //   transform: scaleX(-1);
            // }
          }
          .buttons-section button,
          .btn-share {
            @media screen and (max-width: $mobile) {
              margin-bottom: 12px;
            }
          }
        }
      }

      .event-text {
        height: 100%;
        padding: 0 40px 40px 40px;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .event-date {
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;
          letter-spacing: 0.04em;
          text-transform: uppercase;
        }

        .event-name {
          margin: 20px 0;
        }

        .event-distance {
          display: flex;
          flex-direction: row;

          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          letter-spacing: 0.04em;
          text-transform: uppercase;

          .distance-divider {
            margin: 0 4px;
          }
        }
      }
    }
  }

  .rescheduled-block {
    display: flex;
    flex-direction: column;
    padding: 0 36px;
    background: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .status-bar {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: white;
      text-transform: uppercase;
      padding: 8px 24px;
      background-color: #cf1019;
    }

    .rescheduled-content {
      display: flex;
      justify-content: space-between;

      .content-info {
        display: flex;
        flex-direction: column;
        flex: 1 0;
        color: black;
        padding-top: 24px;
        padding-bottom: 40px;
        width: 100%;

        .info-title {
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          line-height: 25px;
          text-transform: capitalize;
        }

        .info-date {
          display: inline-flex;
          align-items: center;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          margin-top: 24px;

          .date-icon {
            width: 100%;
            height: 100%;
            max-width: 14px;
            max-height: 14px;

            & + .date-text {
              margin-left: 8px;
            }
          }
        }

        .info-description {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          margin-top: 24px;

          .paragraph {
            margin: 0;
          }

          .paragraph + .paragraph {
            margin-top: 8px;
          }
        }
      }

      .content-cta {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 50px;
        padding-right: 6px;

        .cta-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 158px;
          height: 77px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.15);
          box-sizing: border-box;
          box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
          border-radius: 8px;

          .cta-icon {
            width: 26px;
            height: 26px;

            & + .cta-title {
              margin-top: 10px;
            }
          }

          .cta-title {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: black;
          }
        }
      }
    }

    @media screen and (max-width: 499px) {
      padding: 0 16px;

      .rescheduled-content {
        flex-direction: column;
        align-items: center;
        padding-bottom: 34px;

        .content-info {
          padding-top: 30px;
          padding-bottom: 45px;
        }
        .content-cta {
          padding: 0;
        }
      }
    }
  }

  @media screen and (max-width: 499px) {
    .event-background {
      .event-hero {
        .event-header {
          order: 2;
          padding: 0;
          min-height: auto;
          // padding: 0 20px 40px 20px;
          .event-back-btn {
            display: none;

            i {
            }

            span {
            }
          }
        }

        .event-text {
          // padding: 0 20px;
          padding: 0;

          .event-date {
          }
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .event-background {
      .event-hero {
        .event-text {
          // padding: 0 16px;
          width: min-content;

          .event-distance {
            font-size: 12px;
          }

          .event-date {
            font-size: 12px;
          }

          .event-name {
            width: auto;
          }
        }
      }
    }
  }
}
</style>
